import { Fragment, useEffect, useState } from 'react'
import {
  Text,
  Box,
  IconButton,
  Button,
  useToast,
  useColorModeValue,
  SimpleGrid,
  GridItem,
  Checkbox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Wrap,
  Divider,
} from '@chakra-ui/react'
import { useLiveQuery } from 'dexie-react-hooks'
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  DownloadIcon,
  LockIcon,
  StarIcon,
  SunIcon,
  UnlockIcon,
  ViewIcon,
  WarningIcon,
} from '@chakra-ui/icons'
import {
  LuDatabaseBackup,
  LuFlipHorizontal2,
  LuSiren,
  LuCaseUpper,
} from 'react-icons/lu'
import { format } from 'date-fns'
import { isEqual } from 'lodash'
import formatDistance from 'date-fns/formatDistance'
import { MAX_LOGS, palette, paletteDark } from '../config'
import packageJson from '../../package.json'
import { db } from '../store/db'
import { fixSyncProblems } from '../common/syncWithRemote'
import {
  deleteUser,
  hardDeleteUserFromDb,
  undeleteUser,
  updateUser,
} from '../User/user.model'
import { ButtonConfirm, ButtonConfirmDownload } from '../common/UtilComponents'
import './showLogs.css'
import {
  createS3JsonFile,
  deleteS3File,
  getS3Filenames,
} from '../common/s3Functions'
import {
  formatDateTime,
  getWalkLeaderNames,
  isLeader,
  isValidDate,
} from '../common/utils'
import { CompareLocalRemote } from './CompareLocalRemote'
import { Plbs } from '../Plbs/Plbs'
import { LocalBackup } from '../LocalBackup/LocalBackup'
import { CombineUsers } from './CombineUsers'
import { ManageTips } from './ManageTips'
import { useSwipeable } from 'react-swipeable'

type Props = {
  onClick: () => void
  onStepZoom: () => void
}

export const AdminTasks = (props: Props) => {
  const toast = useToast()
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const [adminCount, setAdminCount] = useState(0)
  const [walksUrl, setWalksUrl] = useState<string | undefined>()
  const [walkersUrl, setWalkersUrl] = useState<string | undefined>()
  const [show, setShow] = useState('')
  const [amSuperAdmin, setAmSuperAdmin] = useState(false)
  const [amAdmin, setAdmin] = useState(false)
  const [lockIds, setLockIds] = useState<string[]>([])

  // Handle swipe to close
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.stopPropagation()
      if (eventData.dir === 'Right') props.onClick()
    },
  })

  // Effect to get the admin status
  useEffect(() => {
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie?.userId)
    if (!user) return // Sometimes usersDexie comes in late
    setAmSuperAdmin(Boolean(user?.isSuperAdmin))
    setAdmin(Boolean(user?.isAdmin))
  }, [usersDexie, appStatusDexie?.userId])

  useEffect(() => {
    getS3Filenames('sync-lock').then((_lockIds) => {
      if (!isEqual(_lockIds, lockIds)) setLockIds(_lockIds)
    })
  }, [])

  // Get the logs - limited and in reverse,
  const logsDex = useLiveQuery(() => {
    return db.logItems.reverse().limit(MAX_LOGS).toArray()
  })

  const toggleVisitorUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    await updateUser({ ...user, isVisitor: !user.isVisitor })
  }

  const toggleAdminUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    await updateUser({ ...user, isAdmin: !user.isAdmin })
  }

  const toggleSuperAdminUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    await updateUser({ ...user, isSuperAdmin: !user.isSuperAdmin })
  }

  const toggleBlockUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    await updateUser({ ...user, isBlocked: !user.isBlocked })
  }

  const becomeUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    db.appStatus.update('Tripsheets', { userId })
    props.onClick()
  }

  const toggleStatusUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    if (user.status === 'deleted') {
      await undeleteUser(userId)
    } else {
      await deleteUser(userId)
    }
  }

  // Returns as string 0/0 | <nrwalks>/<nrleads>
  const getWalkCount = (userId: string, returnList = false) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return '?/?'
    // Check if user is used
    const nrWalks = walksDexie?.filter((w) => w.walkers.includes(userId)).length
    const nrLeads = walksDexie?.filter((w) => isLeader(userId, w)).length
    if (!returnList) return `${nrWalks}/${nrLeads}`

    const walks = walksDexie?.filter((w) => w.walkers.includes(userId)) || []
    const walksList = walks.map((w) => {
      const asLeader = isLeader(userId, w) ? ' (leader)' : ''
      return (
        <p>
          {w.title}
          {asLeader}
        </p>
      )
    })
    return <>{walksList}</>
  }

  const hardDeleteUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (user?.status !== 'deleted') return
    await hardDeleteUserFromDb(userId)
  }

  const removeSyncLock = async () => {
    toast({
      title: `Wait 10 seconds...`,
      duration: 12_000,
      isClosable: true,
    })
    await fixSyncProblems()
  }

  const closeOpenApp = async () => {
    if (!appStatusDexie) return
    if (appStatusDexie.isClosed) {
      // Delete the is-closed file on S3
      const ids = await getS3Filenames('is-closed')
      await deleteS3File('is-closed', ids[0])
    } else {
      // Create an is-closed file on S3
      await createS3JsonFile(
        'is-closed',
        appStatusDexie?.userId,
        new Date().toISOString()
      )
    }
    toast({
      title: `Reload the app`,
      isClosable: true,
    })
  }

  const displayLogs = () => {
    if (!logsDex) return null
    return logsDex.map((log, index) => {
      return (
        <p key={(log?.id?.toString() ?? '') + log.time + index.toString()}>
          {log.time}: {log.data}
          {log.repeat > 1 ? ` [${log.repeat}x]` : ''}
        </p>
      )
    })
  }

  const displayUsers = () => {
    const allUsers =
      usersDexie?.sort((a, b) => a.fullName.localeCompare(b.fullName)) || []

    return (
      <SimpleGrid columns={amSuperAdmin ? 9 : 4}>
        <GridItem colSpan={2}>
          <Text as={'i'}>Name ({allUsers.length})</Text>
        </GridItem>
        {amSuperAdmin && (
          <GridItem>
            <Text as={'i'}>Active</Text>
          </GridItem>
        )}

        <GridItem>
          <Text as={'i'}>Visitor</Text>
        </GridItem>
        <GridItem>
          <Text as={'i'}>Admin</Text>
        </GridItem>
        {amSuperAdmin && (
          <GridItem>
            <Text as={'i'}>SuperAdmin</Text>
          </GridItem>
        )}
        {amSuperAdmin && (
          <GridItem>
            <Text as={'i'}>Block</Text>
          </GridItem>
        )}
        {amSuperAdmin && <GridItem>{/* Me btn */}</GridItem>}
        {amSuperAdmin && (
          <GridItem>
            <Text as={'i'}>Id</Text>
          </GridItem>
        )}

        {allUsers?.map((u) => {
          if (!amSuperAdmin && u.status === 'deleted') return null
          return (
            <Fragment key={u.userId}>
              <GridItem colSpan={2}>
                <Text
                  as={
                    u.status === 'deleted'
                      ? 'del'
                      : u.userId === appStatusDexie?.userId
                      ? 'b'
                      : undefined
                  }
                >
                  {u.fullName} {u.isVisitor && <Text as={'sup'}> (v)</Text>}
                  {u.userId === appStatusDexie?.userId && (
                    <Text as={'sup'}>(me)</Text>
                  )}
                </Text>
              </GridItem>
              {amSuperAdmin && (
                <GridItem>
                  <Checkbox
                    id={'active-' + u.userId}
                    isChecked={u.status === 'active'}
                    onChange={() => toggleStatusUser(u.userId)}
                    isDisabled={!amSuperAdmin}
                  />
                  <Popover isLazy placement={'right'}>
                    <PopoverTrigger>
                      <Button
                        size={'xs'}
                        variant={'outline'}
                        className={'upABit'}
                      >
                        {getWalkCount(u.userId)}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      {getWalkCount(u.userId, true)}
                    </PopoverContent>
                  </Popover>
                  {u.status === 'deleted' &&
                    getWalkCount(u.userId) === '0/0' && (
                      <ButtonConfirm
                        icon={<DeleteIcon />}
                        okAction={() => hardDeleteUser(u.userId)}
                        text={`Hard delete ${u.fullName}? Must sync Before & After!`}
                        btnText={''}
                      />
                    )}
                </GridItem>
              )}
              <GridItem>
                <Checkbox
                  id={'visitor-' + u.userId}
                  isChecked={u.isVisitor}
                  onChange={() => toggleVisitorUser(u.userId)}
                />
              </GridItem>
              <GridItem>
                <Checkbox
                  id={'canSee-' + u.userId}
                  isChecked={u.isAdmin}
                  onChange={() => toggleAdminUser(u.userId)}
                />
              </GridItem>
              {amSuperAdmin && (
                <GridItem>
                  <Checkbox
                    id={'admin-' + u.userId}
                    isChecked={u.isSuperAdmin}
                    onChange={() => toggleSuperAdminUser(u.userId)}
                  />
                </GridItem>
              )}
              {amSuperAdmin && (
                <GridItem>
                  <Checkbox
                    id={'block-' + u.userId}
                    isChecked={u.isBlocked}
                    onChange={() => toggleBlockUser(u.userId)}
                  />
                </GridItem>
              )}
              {amSuperAdmin && (
                <GridItem>
                  <ButtonConfirm
                    icon={<SunIcon />}
                    okAction={() => becomeUser(u.userId)}
                    text={'Become ' + u.fullName}
                    btnText={'Me'}
                  />
                </GridItem>
              )}
              {amSuperAdmin && (
                <GridItem>
                  <Text as={'i'} fontSize={'xs'}>
                    {u.userId}
                  </Text>
                </GridItem>
              )}
            </Fragment>
          )
        })}
      </SimpleGrid>
    )
  }

  const makeAdmin = async () => {
    if (adminCount === 4) {
      // Make this user a canSee
      const appStatus = await db.appStatus.get('Tripsheets')
      if (!appStatus?.userId) return
      const user = await db.users.get(appStatus.userId)
      if (!user) return
      await updateUser({ ...user, isAdmin: !user.isAdmin })
      toast({
        title: `${user.fullName} is ${user.isAdmin ? 'NOT' : ''} an admin`,
        isClosable: true,
      })
    }
    if (adminCount >= 8) {
      // Make this user an admin
      const appStatus = await db.appStatus.get('Tripsheets')
      if (!appStatus?.userId) return
      const user = await db.users.get(appStatus.userId)
      if (!user) return
      await updateUser({ ...user, isSuperAdmin: !user.isSuperAdmin })
      setAdminCount(0)
      toast({
        title: `${user.fullName} is ${
          user.isSuperAdmin ? 'NOT' : ''
        } a super admin`,
        isClosable: true,
      })
      props.onClick()
    }
    setAdminCount(adminCount + 1)
  }

  const downloadCsv = async () => {
    if (!walksDexie || !walksDexie.length) return
    // Make WALKS csv
    const activeWalksDexie = [...walksDexie]
    activeWalksDexie.sort((a, b) => b.endedAt.localeCompare(a.endedAt))

    // Prepare names of walkers
    const walksWithWalkers = activeWalksDexie.map((w) => {
      const names = w.walkers.map(
        (wId) => usersDexie?.find((u) => u.userId === wId)?.fullName || ''
      )
      w.walkers = names

      w.leaderId = getWalkLeaderNames(w, usersDexie)

      return w
    })

    let columns = [
      'Walk',
      'Status',
      'Date',
      'Leader',
      'Meet',
      'Start time',
      'End time',
      'Duration',
      'Start lon/lat',
      'End lon/lat',
      'Walkers',
      'Nr Walkers',
    ]

    let dataRows = walksWithWalkers.map((w) => {
      return [
        w.title,
        w.status,
        isValidDate(w.date) ? formatDateTime(w.date, 'EEE dd-MM-yyyy') : w.date,
        w.leaderId,
        `${w.meetPoint} ${w.meetTime}`,
        w.startedAt ? format(new Date(w.startedAt), 'dd-MM-yyyy HH:mm') : '',
        w.endedAt ? format(new Date(w.endedAt), 'dd-MM-yyyy HH:mm') : '',
        w.startedAt && w.endedAt
          ? formatDistance(new Date(w.endedAt), new Date(w.startedAt))
          : '',
        w.startGps.includes('LON:') ? w.startGps : '',
        w.endGps.includes('LON:') ? w.endGps : '',
        w.walkers.join(', '),
        w.walkers.length.toString(),
      ]
    })

    const newline = '\n'
    const comma = ','
    let allRowsData = columns.join(comma) + newline
    dataRows.map((row) => {
      const rowEscaped = row.map((item) => {
        if (typeof item !== 'string') return item.toString()
        const itemEscaped = item.replaceAll(',', ';')
        return itemEscaped
      })
      const thisRow = rowEscaped.join(comma) + newline
      allRowsData += thisRow
    })

    let fileData = new Blob([allRowsData], { type: 'text/plain' })
    if (walksUrl) window.URL.revokeObjectURL(walksUrl)
    let textFileUrl = window.URL.createObjectURL(fileData)
    setWalksUrl(textFileUrl)

    // Make WALKERS csv
    if (!usersDexie || !usersDexie.length) return

    const activeUsersDexie = usersDexie.filter((w) => w.status !== 'deleted')
    activeUsersDexie.sort((a, b) => a.fullName.localeCompare(b.fullName))

    columns = [
      'Walker',
      'Status',
      'Visitor',
      'Phone',
      'Emergency phone',
      'Emergency name',
      'Emergency phone other',
      'Emergency phone other',
      'Address',
      'Medical form',
      'Medical form location',
      'Number of walks',
      'Number of leads',
    ]

    dataRows = activeUsersDexie.map((u) => {
      const walksDone = activeWalksDexie.filter((walk) => {
        return walk.walkers.includes(u.fullName)
      })
      // Number of walks I have lead. Note: leaderId has been replaced with names
      const walksLead = activeWalksDexie.filter((walk) => {
        return walk.leaderId.includes(u.fullName)
      })
      return [
        u.fullName,
        u.isBlocked ? 'blocked' : u.status,
        u.isVisitor ? 'Yes' : '',
        amSuperAdmin || amAdmin || !u.isPrivateDetails
          ? u.phone.toString()
          : '******',
        amSuperAdmin || amAdmin || !u.isPrivateDetails
          ? u.emergencyPhone.toString()
          : '******',
        amSuperAdmin || amAdmin || !u.isPrivateDetails
          ? u.emergencyName?.toString()
          : '******',
        amSuperAdmin || amAdmin || !u.isPrivateDetails
          ? u.emergencyPhone1?.toString()
          : '******',
        amSuperAdmin || amAdmin || !u.isPrivateDetails
          ? u.emergencyName1?.toString()
          : '******',
        amSuperAdmin || amAdmin
          ? u.address?.replaceAll('\n', ', ') || ''
          : '******',
        u.medicalForm ? 'Yes' : 'No',
        u.medicalFormLocation,
        walksDone.length.toString(),
        walksLead.length.toString(),
      ]
    })

    allRowsData = columns.join(comma) + newline
    dataRows.map((row) => {
      const rowEscaped = row.map((item) => {
        if (!item) return ''
        if (typeof item !== 'string') return item.toString()
        const itemEscaped = item.replaceAll(',', ';')
        return itemEscaped
      })
      const thisRow = rowEscaped.join(comma) + newline
      allRowsData += thisRow
    })

    fileData = new Blob([allRowsData], { type: 'text/plain' })
    if (walkersUrl) window.URL.revokeObjectURL(walkersUrl)
    textFileUrl = window.URL.createObjectURL(fileData)
    setWalkersUrl(textFileUrl)
  }

  const deleteDexie = async () => {
    await db.delete()
    toast({
      title: `Restarting the app...`,
      isClosable: true,
    })
    location.reload()
  }

  return (
    <Box {...swipeHandlers}>
      <Box className={'adminTasksContainer'} bg={heavyBgColor}>
        <Box>
          {/* Header */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text as={'b'} onClick={makeAdmin}>
              Admin tasks
            </Text>
            <Text as={'i'} fontSize={'xs'}>
              <Box ml={5}>
                App version: {packageJson.version}
                {`-${appStatusDexie?.clubId.toLowerCase()}`}
              </Box>
            </Text>
            {/* Close icon */}
            <IconButton
              colorScheme={palette.action}
              aria-label="Close Search"
              icon={<CloseIcon />}
              size={'xs'}
              onClick={props.onClick}
            />
          </Box>
          {/* All the buttons */}
          <Wrap
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {/* Step text size */}
            <Button
              aria-label="Enlarge the text"
              size={'xs'}
              height={8}
              leftIcon={<LuCaseUpper />}
              variant="solid"
              onClick={() => props.onStepZoom()}
            >
              Text size
            </Button>
            {/* Download csv */}
            <ButtonConfirmDownload
              text={'fake'}
              btnText={'Get Excel data'}
              icon={<DownloadIcon />}
              okAction={downloadCsv}
              walksUrl={walksUrl}
              walkersUrl={walkersUrl}
            />
            {/* Start all over */}
            <Box ml={2}>
              <ButtonConfirm
                text={'fully'}
                btnText={'Re-install'}
                icon={<WarningIcon />}
                okAction={deleteDexie}
                isDisabled={!appStatusDexie?.haveInternet}
              />
            </Box>
            {/* Show the logs */}
            <Button
              aria-label="Show the logs"
              size={'xs'}
              height={8}
              leftIcon={<ViewIcon />}
              variant="solid"
              onClick={() => setShow(show === 'theLogs' ? '' : 'theLogs')}
            >
              Show system logs
            </Button>
          </Wrap>
          <Wrap
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {/* PLBs */}
            {(amSuperAdmin || amAdmin) && (
              <Button
                aria-label="manage the PLBs"
                size={'sm'}
                leftIcon={<LuSiren />}
                variant="solid"
                onClick={() => setShow(show === 'plbs' ? '' : 'plbs')}
              >
                PLBs
              </Button>
            )}
            {/* Show users */}
            {(amSuperAdmin || amAdmin) && (
              <Button
                aria-label="Show the users"
                size={'xs'}
                height={8}
                leftIcon={<SunIcon />}
                variant="solid"
                onClick={() => setShow(show === 'users' ? '' : 'users')}
              >
                Show users
              </Button>
            )}
            {/* Backup */}
            {(amSuperAdmin || amAdmin) && (
              <Button
                aria-label="Manage backups"
                size={'xs'}
                height={8}
                leftIcon={<LuDatabaseBackup />}
                variant="solid"
                onClick={() => setShow(show === 'backup' ? '' : 'backup')}
              >
                Manage backups
              </Button>
            )}
          </Wrap>
          <Wrap
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {/* Tips */}
            {amSuperAdmin && (
              <Button
                aria-label="Manage the tips"
                size={'xs'}
                height={8}
                leftIcon={<StarIcon />}
                variant="solid"
                isDisabled={!appStatusDexie?.haveInternet}
                onClick={() => setShow(show === 'tips' ? '' : 'tips')}
              >
                Tips
              </Button>
            )}
            {/* Compare local <-> remote */}
            {amSuperAdmin && (
              <Button
                aria-label="Compare local vs remote data"
                size={'sm'}
                leftIcon={<LuFlipHorizontal2 />}
                variant="solid"
                onClick={() => setShow(show === 'compare' ? '' : 'compare')}
              >
                Local/Remote
              </Button>
            )}
            {/* Close/Open the app */}
            {amSuperAdmin && (
              <ButtonConfirm
                icon={appStatusDexie?.isClosed ? <UnlockIcon /> : <LockIcon />}
                okAction={closeOpenApp}
                text={'for all'}
                btnText={appStatusDexie?.isClosed ? 'Open app' : 'Close app'}
              />
            )}
            {/* Fix the sync */}
            {amSuperAdmin && (
              <ButtonConfirm
                text={`the sync (${lockIds.length} locks)`}
                btnText={'Fix and unlock'}
                okAction={removeSyncLock}
                icon={<UnlockIcon />}
              />
            )}
            {amSuperAdmin && (
              <Button
                aria-label="Combine 2 users together"
                size={'xs'}
                height={8}
                leftIcon={<AddIcon />}
                variant="solid"
                onClick={() =>
                  setShow(show === 'combineUsers' ? '' : 'combineUsers')
                }
              >
                Combine Users
              </Button>
            )}
          </Wrap>
          {/****************/}
          {/* Content area */}
          <Divider m={4} />
          {show === 'theLogs' && (
            <Box onClick={props.onClick}>{displayLogs()}</Box>
          )}
          {show === 'users' && <Box>{displayUsers()}</Box>}
          {show === 'compare' && (
            <Box>
              <CompareLocalRemote />
            </Box>
          )}{' '}
          {show === 'plbs' && (
            <Box>
              <Plbs />
            </Box>
          )}
          {show === 'backup' && (
            <Box>
              <LocalBackup />
            </Box>
          )}
          {show === 'combineUsers' && (
            <Box>
              <CombineUsers />
            </Box>
          )}{' '}
          {show === 'tips' && (
            <Box>
              <ManageTips />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
