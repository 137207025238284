import React from 'react'
import { IconButton } from '@chakra-ui/react'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { db } from '../store/db'

type Props = {
  from: 'walks' | 'user'
}

export const HelpIcon = (props: Props) => {
  return (
    <IconButton
      icon={<QuestionOutlineIcon />}
      size={'sm'}
      onClick={() => {
        db.appStatus.update('Tripsheets', {
          navTo: 'help',
          parameters: props.from,
        })
      }}
      aria-label={'Show help screen'}
    />
  )
}
