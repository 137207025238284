import React, { ReactElement, useEffect, useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import {
  AddIcon,
  ArrowRightIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  HamburgerIcon,
  InfoOutlineIcon,
} from '@chakra-ui/icons'
import { db, User, Walk } from '../store/db'
import { palette, paletteDark } from '../config'
import { ButtonConfirm } from '../common/UtilComponents'
import {
  formatDateTime,
  formatMultiDateTime,
  formatWalkTitle,
  isLeader,
} from '../common/utils'
import { getBadgeFor, getBorderColorClass } from './Walks.helpers'
import { addLeaderFor, endWalk, startWalk } from './walks.model'
import { WalkersList } from './WalkersList'
import { sendStandardMessage } from '../Messages/messages.model'
import { LuBeer } from 'react-icons/lu'

type Props = {
  walks: Walk[]
  showInfoSetter: (id: string) => void
  showWalkersListSetter: (id: string) => void
  registerMe: (id: string, action?: 'cancel') => void
  editWalk: (id: string) => void
}

export const MyWalks = (props: Props) => {
  const toast = useToast()
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const lightBgColor = useColorModeValue(
    palette.lightBackground,
    paletteDark.lightBackground
  )
  const [showStartEndBtns, setShowStartEndBtns] = useState('')
  const [showMoreLeadersOptions, setShowMoreLeadersOptions] = useState('')
  const [showMoreWalkersOptions, setShowMoreWalkersOptions] = useState('')
  const [user, setUser] = useState<User>()
  const [amSuperAdmin, setAmSuperAdmin] = useState(false)

  // Effect to get the admin status
  useEffect(() => {
    const _user = usersDexie?.find((u) => u?.userId === appStatusDexie?.userId)
    setUser(_user)
    setAmSuperAdmin(Boolean(_user?.isSuperAdmin))
  }, [usersDexie, appStatusDexie?.userId])

  const editWalk = (walkId: string) => {
    props.editWalk(walkId)
  }

  const startEndButtons = (walk: Walk) => {
    return walk.status === 'started' ? (
      <ButtonConfirm
        text={formatWalkTitle(walk.title, true)}
        btnText={'End'}
        triggerAction={() =>
          setShowStartEndBtns(
            showStartEndBtns === walk.walkId ? '' : walk.walkId
          )
        }
        okAction={async () => {
          await endWalk(walk.walkId)
          await sendStandardMessage('walkEnded', walk.walkId)
          setShowStartEndBtns('')

          if (!appStatusDexie?.haveInternet) {
            toast({
              title: `Don't forget to sync when internet is available again`,
              position: 'top',
              status: 'warning',
              duration: 12_000,
              isClosable: true,
            })
          }
        }}
        icon={<CloseIcon />}
      />
    ) : (
      <ButtonConfirm
        text={formatWalkTitle(walk.title, true)}
        btnText={'Start'}
        triggerAction={() =>
          setShowStartEndBtns(
            showStartEndBtns === walk.walkId ? '' : walk.walkId
          )
        }
        okAction={() => {
          startWalk(walk.walkId)
          setShowStartEndBtns('')
        }}
        icon={<ArrowRightIcon />}
      />
    )
  }

  const getButtonsFor = (walk: Walk): ReactElement => {
    const amLeader = isLeader(user, walk)

    return (
      <Box>
        {/* Various action button */}
        {/* Draft OR LeaderBtns Or UserBtns */}
        {walk.status === 'draft' ? (
          // Btns for draft walk
          <Button
            leftIcon={<CheckIcon />}
            size={'sm'}
            variant="outline"
            colorScheme={palette.actionSec}
            onClick={() => props.editWalk(walk.walkId)}
          >
            Edit
          </Button>
        ) : amLeader || amSuperAdmin ? (
          // LeaderBtns: Start, End, menu for AddWalker, Edit
          <Box display={'flex'}>
            {startEndButtons(walk)}
            <IconButton
              icon={<HamburgerIcon />}
              size={'sm'}
              ml={1}
              mr={2}
              onClick={() => {
                setShowStartEndBtns('')
                setShowMoreLeadersOptions(
                  showMoreLeadersOptions === walk.walkId ? '' : walk.walkId
                )
              }}
              aria-label={'More options for leaders'}
            />
          </Box>
        ) : (
          // UserBtns: menu for Cancel, MakeMeLeader
          <Box display={'flex'}>
            <IconButton
              icon={<HamburgerIcon />}
              size={'sm'}
              ml={1}
              mr={2}
              onClick={() => {
                setShowMoreWalkersOptions(
                  showMoreWalkersOptions === walk.walkId ? '' : walk.walkId
                )
              }}
              aria-label={'More options for walkers'}
            />
          </Box>
        )}
      </Box>
    )
  }

  const addAsLeader = (walkId?: string, userId?: string, action?: 'remove') => {
    if (!walkId || !userId) return
    setShowMoreWalkersOptions('')
    addLeaderFor(walkId, userId, action)
  }

  const nrLeaders = (walk: Walk) =>
    typeof walk.leaderId === 'string' ? 1 : walk.leaderId.length

  return (
    <>
      <Box className={'listItemContainer'}>
        <Box className={'registerWalksList'}>
          <Text as={'b'}>My walks:</Text>
          <Box className={'registerWalksList'}>
            {/* Each walk */}
            {props.walks.map((w) => (
              <Box key={w.walkId}>
                <Box key={'my-' + w.walkId} className={'registerWalksItem'}>
                  <Box
                    className={`walkItem ${getBorderColorClass(
                      walksDexie,
                      w.walkId
                    )}`}
                  >
                    <IconButton
                      icon={<InfoOutlineIcon />}
                      size={'sm'}
                      mr={2}
                      onClick={() => props.showInfoSetter(w.walkId)}
                      aria-label={'Info about the walk'}
                    />
                    <Box className={'walkTitleBadgesDate'}>
                      <Flex className={'walkTitleBadges'}>
                        <Box className={'walkTitle'}>
                          {formatWalkTitle(w.title, true)}
                        </Box>
                        {getBadgeFor(w, appStatusDexie)}
                      </Flex>
                      <Text fontSize={'xs'}>
                        {w?.category !== 'multiday'
                          ? formatDateTime(w?.date, 'EEEE d MMM yyyy')
                          : formatMultiDateTime(w?.date, w?.dateTo)}
                      </Text>
                    </Box>
                  </Box>
                  {getButtonsFor(w)}
                </Box>
                <Box
                  display={showStartEndBtns === w.walkId ? 'flex' : 'none'}
                  className={'startBtnsContainer'}
                >
                  {/* Space for start + end btns */}
                </Box>

                {/* Leaders Options */}
                <Box
                  display={
                    showMoreLeadersOptions === w.walkId ? 'flex' : 'none'
                  }
                  className={'moreOptionsLeaders'}
                  bg={lightBgColor}
                >
                  {/* Un-Make me a leader */}
                  {nrLeaders(w) > 1 && (
                    <ButtonConfirm
                      text={'this walk'}
                      btnText={'Not Leading'}
                      okAction={() => {
                        setShowMoreLeadersOptions('')
                        addAsLeader(w.walkId, user?.userId, 'remove')
                      }}
                      variant={'outline'}
                      icon={<LuBeer />}
                    />
                  )}
                  {/* Add a walker */}
                  <Button
                    leftIcon={<AddIcon />}
                    size={'sm'}
                    variant="outline"
                    mr={2}
                    ml={2}
                    colorScheme={palette.actionSec}
                    onClick={() => {
                      setShowMoreLeadersOptions('')
                      props.showWalkersListSetter(w.walkId)
                    }}
                  >
                    Walker
                  </Button>
                  {/* Edit the walk */}
                  <Button
                    leftIcon={<CheckIcon />}
                    size={'sm'}
                    variant="outline"
                    colorScheme={palette.actionSec}
                    onClick={() => {
                      setShowMoreLeadersOptions('')
                      editWalk(w.walkId)
                    }}
                  >
                    Edit
                  </Button>
                  {/* Make me a leader for non-leading admins */}
                  {!w.leaderId.includes(user?.userId || '') && (
                    <ButtonConfirm
                      text={'for this walk'}
                      btnText={'Make me a Leader'}
                      okAction={() => addAsLeader(w.walkId, user?.userId)}
                      variant={'outline'}
                      icon={<LuBeer />}
                    />
                  )}
                  {/* Cancel for non-leading admins */}
                  {w.status !== 'started' &&
                    !w.leaderId.includes(user?.userId || '') && (
                      <Button
                        leftIcon={<DeleteIcon />}
                        size={'sm'}
                        ml={2}
                        variant="outline"
                        colorScheme={palette.actionSec}
                        onClick={() => {
                          setShowMoreLeadersOptions('')
                          props.registerMe(w.walkId, 'cancel')
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                </Box>

                {/* Walkers Options */}
                <Box
                  display={
                    showMoreWalkersOptions === w.walkId ? 'flex' : 'none'
                  }
                  className={'moreOptionsLeaders'}
                  bg={lightBgColor}
                >
                  {/* Make me a leader */}
                  <ButtonConfirm
                    text={'for this walk'}
                    btnText={'Make me a Leader'}
                    okAction={() => addAsLeader(w.walkId, user?.userId)}
                    variant={'outline'}
                    icon={<LuBeer />}
                  />

                  {/* Cancel btn */}
                  {w.status !== 'started' &&
                    !w.leaderId.includes(user?.userId || '') && (
                      <Button
                        leftIcon={<DeleteIcon />}
                        size={'sm'}
                        ml={2}
                        variant="outline"
                        colorScheme={palette.actionSec}
                        onClick={() => {
                          setShowMoreWalkersOptions('')
                          props.registerMe(w.walkId, 'cancel')
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}
