import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  StackDivider,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { AddIcon, CheckIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import React, { useEffect, useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { useSwipeable } from 'react-swipeable'
import { AppStatus, db, SyncOptions, Walk } from '../store/db'
import { palette } from '../config'
import { registerFor } from './walks.model'
import { WalkInfo } from './WalkInfo'
import {
  formatDateTime,
  formatMultiDateTime,
  formatWalkTitle,
  isLeader,
  log,
} from '../common/utils'
import { ErrorScreen } from '../common/ErrorScreen'
import {
  syncWithRemote,
  updateSingleSyncProperty,
} from '../common/syncWithRemote'
import { MyWalks } from './MyWalks'
import { getBadgeFor, getBorderColorClass, sortOnADate } from './Walks.helpers'
import { WalkHistory } from './WalkHistory'
import { WalkersList } from './WalkersList'
import { MakeWalk } from './MakeWalk'
import { HelpIcon } from '../common/Helpicon'

export const Walks = () => {
  const toast = useToast()

  let appStatusDexie: AppStatus | undefined
  let walksDexie: Walk[] | undefined
  try {
    appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
    walksDexie = useLiveQuery(() => db.walks.toArray())
  } catch (error: any) {
    console.error('Database ERROR during walks start', error)
    log('Database ERROR during walks start')
    return ErrorScreen(error)
  }

  const [myWalks, setMyWalks] = useState<Walk[]>([])
  const [openWalks, setOpenWalks] = useState<Walk[]>([])
  const [startedWalks, setStartedWalks] = useState<Walk[]>([])
  const [endedWalks, setEndedWalks] = useState<Walk[]>([])
  const [haveDraftWalk, setHaveDraftWalk] = useState(false)
  const [amLeading, setAmLeading] = useState(false)
  const [showMakeWalkIsOpen, setShowMakeWalkIsOpen] = useState(false)
  const [showWalkHistoryIsOpen, setShowWalkHistoryIsOpen] = useState(false)
  const [showWalkInfoFor, setShowWalkInfoFor] = useState('')
  const [showWalkersListFor, setShowWalkersListFor] = useState('')
  const [editWalkId, setEditWalkId] = useState('')

  // Handle swipe to help page
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right') {
        db.appStatus.update('Tripsheets', {
          navTo: 'help',
          parameters: 'walks',
        })
      }
    },
  })

  // Effect to get the latest walks per status
  useEffect(() => {
    if (!appStatusDexie?.userId) return
    if (!walksDexie) return
    const myWalksDexie =
      walksDexie?.filter(
        (w) =>
          w.status !== 'ended' &&
          w.status !== 'deleted' &&
          w.status !== 'cancelled' &&
          (isLeader(appStatusDexie?.userId, w) || // As a leader
            w.walkers?.includes(appStatusDexie?.userId as string)) // As a walker
      ) || []
    sortOnADate(myWalksDexie, 'asc')
    setMyWalks(myWalksDexie)
    const myWalksIds = myWalksDexie.map((w) => w.walkId)

    // Get some statusses about my walks
    const myDraftWalk = myWalksDexie?.filter((w) => w.status === 'draft') || []
    setHaveDraftWalk(Boolean(myDraftWalk.length))
    const myLeadingWalks =
      myWalksDexie?.filter(
        (w) => w.status === 'started' && isLeader(appStatusDexie?.userId, w)
      ) || []
    setAmLeading(Boolean(myLeadingWalks.length))

    // Find all walks open for registration
    const openWalksDexie =
      walksDexie?.filter(
        (w) =>
          w.status === 'open' &&
          !w.walkers?.includes(appStatusDexie?.userId as string)
      ) || []
    sortOnADate(openWalksDexie, 'asc')
    setOpenWalks(openWalksDexie)

    // Find all walks started
    const startedWalksDexie =
      walksDexie?.filter(
        (w) => w.status === 'started' && !myWalksIds.includes(w.walkId)
      ) || []
    sortOnADate(startedWalksDexie, 'asc')
    setStartedWalks(startedWalksDexie)

    // Find all walks ended
    const endedWalksDexie =
      walksDexie?.filter(
        (w) => w.status === 'ended' || w.status === 'cancelled'
      ) || []
    sortOnADate(endedWalksDexie)
    setEndedWalks(endedWalksDexie.slice(0, 4))
  }, [walksDexie, appStatusDexie?.userId])

  const registerMe = async (walkId: string, action?: 'cancel') => {
    if (!action) {
      const nrWalkers = await registerFor(walkId, appStatusDexie?.userId)
      log(`Registration for a walk${nrWalkers ? ':' : ' failed:'}`, true, {
        userId: appStatusDexie?.userId,
        walkId,
      })
      toast({
        title: nrWalkers
          ? 'Registered! You acknowledge all risks'
          : 'Error - Registration failed',
        description: nrWalkers ? '' : 'Please try again or contact support',
        status: nrWalkers ? 'success' : 'error',
        duration: nrWalkers ? 5000 : 8000,
        isClosable: true,
        position: 'top-right',
      })
      if (nrWalkers) {
        setTimeout(() => {
          toast({
            title: 'Tip: always wait for the sync tick!',
            status: 'info',
            isClosable: true,
            position: 'top-right',
          })
        }, 1000)
      }

      const options: SyncOptions = {
        action: '',
        sanityCheck: { registerFor: walkId },
      }
      await updateSingleSyncProperty([{ key: 'options', value: options }])
      await syncWithRemote()
      return
    }

    // Cancel a registration
    const nrWalkers = await registerFor(
      walkId,
      appStatusDexie?.userId,
      'cancel'
    )
    log(
      `Cancellation for a walk${nrWalkers !== null ? ':' : ' failed:'}`,
      true,
      {
        userId: appStatusDexie?.userId,
        walkId,
      }
    )
    toast({
      title:
        nrWalkers !== null
          ? 'Please also notify the leader (text message is fine)'
          : 'Error - Cancellation failed',
      description:
        nrWalkers !== null ? '' : 'Please try again or contact support',
      status: nrWalkers !== null ? 'success' : 'error',
      duration: nrWalkers !== null ? 5000 : 8000,
      isClosable: true,
      position: 'top-right',
    })

    await syncWithRemote()
    return
  }

  const editWalk = async (walkId?: string) => {
    if (!walkId) return
    setShowMakeWalkIsOpen(true)
    setEditWalkId(walkId)
  }

  return (
    <Box {...swipeHandlers}>
      <Box className={'mainContentContainer'}>
        <Box className={'helpIconContainer'}>
          <HelpIcon from={'walks'} />
        </Box>
        <Box className={'walksContainer'}>
          <VStack divider={<StackDivider borderColor="gray.200" />}>
            {/* My walks */}
            {myWalks.length && (
              <MyWalks
                walks={myWalks}
                showInfoSetter={setShowWalkInfoFor}
                showWalkersListSetter={setShowWalkersListFor}
                registerMe={registerMe}
                editWalk={editWalk}
              />
            )}
            {/* Walks in Progress */}
            {startedWalks.length && (
              <Box className={'listItemContainer'}>
                <Box className={'registerWalksList'}>
                  <>
                    <Text as={'b'}>Other walks in progress:</Text>
                    {startedWalks.map((w: Walk) => (
                      <Box
                        key={'reg-' + w.walkId}
                        className={'registerWalksItem'}
                      >
                        <Box
                          className={`walkItem ${getBorderColorClass(
                            walksDexie,
                            w.walkId
                          )}`}
                        >
                          {' '}
                          <IconButton
                            icon={<InfoOutlineIcon />}
                            size={'sm'}
                            mr={2}
                            onClick={() => setShowWalkInfoFor(w.walkId)}
                            aria-label={'Info about the walk'}
                          />
                          <Box mr={2}>{w.title}</Box>
                          {getBadgeFor(w, appStatusDexie)}
                        </Box>
                      </Box>
                    ))}
                  </>
                </Box>
              </Box>
            )}
            {/* Registration */}
            <Box className={'listItemContainer'}>
              {openWalks.length ? (
                <Box className={'registerWalksList'}>
                  <Text as={'b'}>
                    Register for {openWalks.length} walk
                    {openWalks.length > 1 ? 's' : ''}:
                  </Text>
                  <Box className={'registerWalksList'}>
                    {openWalks.map((w) => (
                      <Box
                        key={'reg-' + w.walkId}
                        className={'registerWalksItem'}
                      >
                        <Box
                          className={`walkItem ${getBorderColorClass(
                            walksDexie,
                            w.walkId
                          )}`}
                        >
                          <IconButton
                            icon={<InfoOutlineIcon />}
                            size={'sm'}
                            mr={2}
                            onClick={() => setShowWalkInfoFor(w.walkId)}
                            aria-label={'Info about the walk'}
                          />
                          <Box>
                            <Flex>
                              {formatWalkTitle(w.title, true)}
                              {getBadgeFor(w, appStatusDexie)}
                            </Flex>
                            <Text fontSize={'xs'}>
                              {w?.category !== 'multiday'
                                ? formatDateTime(w?.date, 'EEEE d MMM yyyy')
                                : formatMultiDateTime(w?.date, w?.dateTo)}
                            </Text>
                          </Box>
                        </Box>

                        <Button
                          leftIcon={<CheckIcon />}
                          size={'xs'}
                          variant="solid"
                          colorScheme={palette.action}
                          onClick={() => registerMe(w.walkId)}
                          isDisabled={
                            w.isFull ||
                            appStatusDexie?.sync.status === 'syncing'
                          }
                        >
                          Registr
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Text as={'b'}>
                  There aren't any walks open for registration
                </Text>
              )}
            </Box>
            {/* Ended walks */}
            <Box className={'listItemContainer'}>
              <Box className={'registerWalksList'}>
                {Boolean(endedWalks.length) && (
                  <Box alignSelf={'start'}>
                    <Box alignSelf={'start'}>
                      <Text as={'b'}>Recently completed walks:</Text>
                    </Box>
                    {endedWalks.map((w: Walk) => (
                      <Box
                        key={'reg-' + w.walkId}
                        className={'registerWalksItem'}
                      >
                        <Box
                          className={`walkItem ${getBorderColorClass(
                            walksDexie,
                            w.walkId
                          )}`}
                        >
                          <IconButton
                            icon={<InfoOutlineIcon />}
                            size={'sm'}
                            mr={2}
                            onClick={() => setShowWalkInfoFor(w.walkId)}
                            aria-label={'Info about the walk'}
                          />
                          <Box mr={2}>
                            <Text
                              as={w.status === 'cancelled' ? 's' : undefined}
                            >
                              {formatWalkTitle(w.title, true)}
                            </Text>
                            <Text fontSize={'xs'}>
                              {w.category !== 'multiday'
                                ? formatDateTime(w.date, 'EEEE d MMM yyyy')
                                : formatMultiDateTime(w.date, w?.dateTo)}
                            </Text>
                          </Box>
                          {w.walkers.includes(
                            appStatusDexie?.userId as string
                          ) && <Badge colorScheme={'cyan'}>You</Badge>}
                        </Box>
                      </Box>
                    ))}
                    <Button
                      leftIcon={<AddIcon />}
                      size={'sm'}
                      variant="outline"
                      onClick={() =>
                        setShowWalkHistoryIsOpen(!showWalkHistoryIsOpen)
                      }
                    >
                      More
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            <HStack spacing={4} justify={'end'} w={'100%'}>
              {!haveDraftWalk && !amLeading ? (
                <Button
                  leftIcon={<AddIcon />}
                  variant="solid"
                  size={'sm'}
                  m={4}
                  colorScheme={'cyan'}
                  onClick={() => {
                    setEditWalkId('')
                    setShowWalkInfoFor('')
                    setShowMakeWalkIsOpen(!showMakeWalkIsOpen)
                  }}
                >
                  Make a walk
                </Button>
              ) : (
                <Button
                  leftIcon={<AddIcon />}
                  variant="solid"
                  size={'sm'}
                  m={4}
                  colorScheme={'cyan'}
                  isDisabled={true}
                >
                  Only 1 draft walk allowed
                </Button>
              )}
            </HStack>
          </VStack>
        </Box>

        <Box className={'secContentContainer'}>
          {/* Make/Edit a walk panel */}
          {showMakeWalkIsOpen && (
            <MakeWalk
              onClick={() => setShowMakeWalkIsOpen(!showMakeWalkIsOpen)}
              editWalkId={editWalkId}
              repeat={false}
            />
          )}

          {/* Walk info panel */}
          {showWalkInfoFor && (
            <WalkInfo
              walkId={showWalkInfoFor}
              onClick={() => setShowWalkInfoFor('')}
              editWalk={editWalk}
            />
          )}

          {/* Walk history panel */}
          {showWalkHistoryIsOpen && (
            <WalkHistory
              onClick={() => setShowWalkHistoryIsOpen(!showWalkHistoryIsOpen)}
            />
          )}

          {/* WalkersList panel */}
          {showWalkersListFor && (
            <WalkersList
              walkId={showWalkersListFor}
              onClick={() => setShowWalkersListFor('')}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
